<script>
import { SuawParagraph, SuawHeading, SuawDivider, SuawButton } from "@suaw/suaw-component-library";
import organizeCreateImage from "../../../../public/img/home/organize-create-image.png";
import organizeStartImage from "../../../../public/img/home/organize-start-image.png";
import organizeRunImage from "../../../../public/img/home/organize-run-image.png";

export default {
  name: "OrganizeSection",
  components: { SuawParagraph, SuawHeading, SuawDivider, SuawButton },
  data() {
    return {
      organizeCreateImage,
      organizeStartImage,
      organizeRunImage
    };
  }
};
</script>

<template>
  <div class="suaw-organize-section">
    <div class="suaw-organize-section__main-container">
      <div class="suaw-organize-section__heading-container">
        <SuawHeading class="suaw-organize-section__heading top-heading" level="3" alignment="center" content="Empowering You To Lead:" />
        <SuawHeading class="suaw-organize-section__heading second-heading" level="3" alignment="center" content="Start Your Own Writing Community" />
        <SuawHeading
          class="suaw-organize-section__subheading"
          level="3"
          alignment="center"
          content="We provide everything you need to create and lead your own events. Our approach is designed to be flexible, scalable, and easy to implement."
        />
      </div>
      <div class="suaw-organize-section__grid-steps">
        <figure class="suaw-organize-section__grid-step">
          <figcaption class="suaw-organize-section__step-caption create-caption">
            <SuawHeading class="suaw-organize-section__step-heading" alignment="center" content="Create an Account" level="2" />
            <SuawParagraph
              class="suaw-organize-section__step-text"
              alignment="center"
              text="Join to gain access to our resources. Learn about hosting and what to expect. It's free and easy!"
            />
          </figcaption>
          <img class="suaw-organize-section__step-image create-image" :src="organizeCreateImage" alt="Three writers at a table in a friendly discussion." />
        </figure>
        <figure class="suaw-organize-section__grid-step">
          <figcaption class="suaw-organize-section__step-caption start-caption">
            <SuawHeading class="suaw-organize-section__step-heading" alignment="center" content="Start a Community" level="2" />
            <SuawParagraph
              class="suaw-organize-section__step-text"
              alignment="center"
              text="Access our easy-to-follow guides designed to help you organize and run effective writing sessions."
            />
          </figcaption>
          <img class="suaw-organize-section__step-image start-image" :src="organizeStartImage" alt="Close up of a woman's hands typing at a tablet." />
        </figure>
        <figure class="suaw-organize-section__grid-step">
          <figcaption class="suaw-organize-section__step-caption">
            <SuawHeading class="suaw-organize-section__step-heading" alignment="center" content="Run Your Event" level="2" />
            <SuawParagraph
              class="suaw-organize-section__step-text"
              alignment="center"
              text="Host events in your community or online. Choose the time and place to bring writers together to create and succeed."
            />
          </figcaption>
          <img class="suaw-organize-section__step-image" :src="organizeRunImage" alt="Two writers at a table looking at each other." />
        </figure>
      </div>
      <div class="suaw-organize-section__grid-cta">
        <SuawDivider class="suaw-organize-section__cta-divider" type="inner" />
        <SuawHeading
          class="suaw-organize-section__cta-heading"
          level="2"
          content="Become an organizer, start your own writing community, and support other writers in your area!"
        />
        <div class="suaw-organize-section__cta-button-wrapper">
          <SuawButton class="suaw-organize-section__button" type="primary" size="hero" button-text="Become an Organizer" @click="$emit('click')" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-organize-section {
  background: linear-gradient(180deg, var(--sem-color-info-light) 0%, var(--sem-color-background-light) 100%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__main-container {
    max-width: 1264px;
    margin: 0 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 56px;
    row-gap: 56px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      row-gap: 48px;
    }
    @media (max-width: 767px) {
      margin: 0 16px;
    }
    @media (max-width: 650px) {
      row-gap: 42px;
    }
  }
  //header styles
  &__heading-container {
    display: grid;
    grid-template-columns: 1fr;
    justify-self: center;
    grid-column: 1/4;
  }
  &__underline {
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: center;
    align-self: end;
    @media (max-width: 600px) {
      display: none;
    }
  }
  &__heading {
    grid-row: 1/2;
    grid-column: 1/2;
    color: var(--sem-color-text-dark);
    font-size: 48px;
    line-height: 125%;
    font-weight: 600;
    font-family: var(--secondary-font-family);
    z-index: 1;
    margin: 0 5px;
    justify-self: center;
    @media (max-width: 650px) {
      font-size: 42px;
    }
    @media (max-width: 400px) {
      font-size: 40px;
    }
  }
  .top-heading {
    @media (max-width: 650px) {
      width: 90%;
    }
    @media (max-width: 572px) {
      width: 60%;
    }
    @media (max-width: 564px) {
      width: 70%;
    }
    @media (max-width: 490px) {
      width: 85%;
    }
  }
  .second-heading {
    grid-row: 2/3;
  }
  &__subheading {
    max-width: 800px;
    margin-top: 16px;
    color: var(--sem-color-text-medium);
    font-size: 24px;
    @media (max-width: 650px) {
      margin-top: 32px;
    }
    @media (max-width: 450px) {
      font-size: 24px;
    }
  }
  //steps styles
  &__grid-steps {
    grid-column: 1/4;
    grid-row: 2/3;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      row-gap: 48px;
      width: 90%;
      justify-self: center;
    }
    @media (max-width: 650px) {
      width: auto;
    }
  }
  &__grid-step {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 24px;
    @media (max-width: 768px) {
      row-gap: 16px;
    }
  }
  &__step-caption {
    grid-row: 2/3;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 12px;
    @media (max-width: 768px) {
      grid-row: 1/2;
    }
  }
  .create-caption {
    grid-column: 1/2;
  }
  .start-caption {
    grid-column: 2/3;
    @media (max-width: 768px) {
      grid-column: 1/2;
    }
  }
  &__step-heading {
    font-family: var(--secondary-font-family);
    font-weight: bold;
    font-size: 36px;
    @media (max-width: 768px) {
      font-size: 42px;
    }
    @media (max-width: 650px) {
      font-size: 36px;
    }
  }
  &__step-text {
    height: 70px;
    @media (max-width: 768px) {
      height: auto;
      font-size: 24px;
      // width: 75%;
      justify-self: center;
    }
    @media (max-width: 650px) {
      font-size: 20px;
    }
  }
  &__step-image {
    grid-row: 1/2;
    height: 260px;
    width: 100%;
    justify-self: center;
    border-radius: 16px;
    box-shadow: var(--soft-shadow-draft);
    object-fit: cover;
    @media (max-width: 768px) {
      grid-row: 2/3;
    }
  }
  .create-image {
    grid-column: 1/2;
  }
  .start-image {
    grid-column: 2/3;
    @media (max-width: 768px) {
      grid-column: 1/2;
    }
  }
  //cta-styles
  &__grid-cta {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;
    justify-self: center;
    grid-column: 1/4;
    grid-row: 3/4;
    max-width: 800px;
  }
  &__cta-divider {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  &__cta-heading {
    grid-column: 1/2;
    grid-row: 2/3;
    color: var(--sem-color-text-medium);
    font-family: var(--secondary-font-family);
    text-align: center;
    font-weight: bold;
  }
  &__cta-button-wrapper {
    grid-column: 1/2;
    grid-row: 3/4;
    display: flex;
    justify-content: center;
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}
</style>
