<script>
import { SuawNewsletter } from "@suaw/suaw-component-library";
import { validateErrors, formFieldState } from "@/utils/api/validationErrors";
import { required, email } from "vuelidate/lib/validators";
import * as CommonApi from "../api.js";

export default {
  name: "NewsletterSubscribe",
  components: { SuawNewsletter },
  data() {
    return {
      newsletterForm: {
        email: ""
      }
    };
  },
  validations: {
    newsletterForm: {
      email: { required, email }
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async subscribeNewsletter() {
      const result = await CommonApi.subscribeNewsletter(this.newsletterForm.email);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSubscribeClicked(email) {
      this.newsletterForm.email = email;
      //!!skipping validation because the input that needs the formFieldState and validateErrors is hidden within the SuawNewsletter component; backend validation should work anyway
      //this.$v.newsletterForm.$touch();
      //if (this.$v.newsletterForm.$invalid) {
      //  this.$root.$emit("universal-error-message", "Invalid subscribe form");
      //} else {
        const newsletterSubscribeResult = await this.subscribeNewsletter();
        if (newsletterSubscribeResult.success) {
          this.$root.$emit("universal-success-message", "Successfully subscribed to newsletter. Expect a confirmation email.");
        }
      //}
    }
  }
};
</script>

<template>
  <SuawNewsletter @submit="onSubscribeClicked" />
</template>
