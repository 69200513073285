<script>
import { SuawHeading, SuawParagraph, SuawDivider, SuawButton } from "@suaw/suaw-component-library";
import gatherImage from "../../../../public/img/home/how-gather-image.png";
import writeImage from "../../../../public/img/home/how-write-image.png";
import repeatImage from "../../../../public/img/home/how-repeat-image.png";
import logoWriteBackground from "../../../../public/img/home/logo-write-background.svg";
import topArrow from "../../../../public/img/home/how-top-arrow.png";
import gatherArrow from "../../../../public/img/home/how-gather-arrow.svg";
import gatherArrowMobile from "../../../../public/img/home/how-gather-arrow-mobile.svg";
import writeArrow from "../../../../public/img/home/how-write-arrow.svg";
import writeArrowMobile from "../../../../public/img/home/how-write-arrow-mobile.svg";

export default {
  name: "HowSection",
  components: { SuawHeading, SuawParagraph, SuawDivider, SuawButton },
  data() {
    return {
      gatherImage,
      writeImage,
      repeatImage,
      logoWriteBackground,
      topArrow,
      // ctaArrow,
      // ctaArrowMobile,
      gatherArrow,
      gatherArrowMobile,
      writeArrow,
      writeArrowMobile
    };
  }
};
</script>

<template>
  <div class="suaw-how-section">
    <div class="suaw-how-section__main-container">
      <div class="suaw-how-section__grid-header">
        <div class="suaw-how-section__heading-wrapper">
          <div class="suaw-how-section__heading-how-wrapper">
            <SuawHeading class="suaw-how-section__heading" level="1" alignment="center" content="How" />
            <img class="suaw-how-section__top-arrow" :src="topArrow" alt="Top Arrow" />
          </div>
          <SuawHeading class="suaw-how-section__heading" level="1" alignment="center" content="Shut" />
          <SuawHeading class="suaw-how-section__heading" level="1" alignment="center" content="Up" />
          <SuawHeading class="suaw-how-section__heading" level="1" alignment="center" content="&amp;" />
          <div class="suaw-how-section__heading-write-wrapper">
            <b class="suaw-how-section__heading-write">Write!</b>
            <img class="suaw-how-section__logo-background" :src="logoWriteBackground" alt="Logo Write Background" />
          </div>
          <SuawHeading class="suaw-how-section__heading" level="1" alignment="center" content="Works" />
        </div>
        <SuawHeading class="suaw-how-section__subheading" alignment="center" level="3" content="Our events follow a simple yet effective format:" />
      </div>
      <div class="suaw-how-section__grid-steps">
        <figure class="suaw-how-section__grid-step gather-step">
          <figcaption class="suaw-how-section__step-caption gather-caption">
            <SuawHeading class="suaw-how-section__step-heading" alignment="center" content="Gather." level="2" />
            <SuawParagraph
              class="suaw-how-section__step-text"
              alignment="center"
              text="Find an event nearby or online. Connect with writers of all genres and experience levels."
            />
          </figcaption>
          <img class="suaw-how-section__step-image gather-image" :src="gatherImage" alt="Three writers at a table in a friendly discussion." />
          <img class="suaw-how-section__step-arrow gather-arrow" :src="gatherArrow" alt="Gather Arrow" />
          <img class="suaw-how-section__step-arrow-mobile gather-arrow-mobile" :src="gatherArrowMobile" alt="Gather Arrow Mobile" />
        </figure>
        <figure class="suaw-how-section__grid-step write-step">
          <figcaption class="suaw-how-section__step-caption write-caption">
            <SuawHeading class="suaw-how-section__step-heading" alignment="center" content="Write." level="2" />
            <SuawParagraph class="suaw-how-section__step-text" alignment="center" text="Write quietly and independently for a set period of time, free from distractions." />
          </figcaption>
          <img class="suaw-how-section__step-image write-image" :src="writeImage" alt="Close up of a woman's hands typing at a tablet." />
          <img class="suaw-how-section__step-arrow write-arrow" :src="writeArrow" alt="Write Arrow" />
          <img class="suaw-how-section__step-arrow-mobile write-arrow-mobile" :src="writeArrowMobile" alt="Write Arrow Mobile" />
        </figure>
        <figure class="suaw-how-section__grid-step repeat-step">
          <figcaption class="suaw-how-section__step-caption">
            <SuawHeading class="suaw-how-section__step-heading" alignment="center" content="Repeat." level="2" />
            <SuawParagraph class="suaw-how-section__step-text" alignment="center" text="Write for another session, chat with others, or head home. The choice is yours!" />
          </figcaption>
          <img class="suaw-how-section__step-image" :src="repeatImage" alt="Two writers at a table looking at each other." />
        </figure>
      </div>
      <div class="suaw-how-section__grid-cta">
        <SuawDivider class="suaw-how-section__cta-divider" type="inner" />
        <SuawHeading
          class="suaw-how-section__cta-heading"
          level="2"
          content="Create your free account, find a local or online community to join, and start getting your writing done!"
        />
        <div class="suaw-how-section__cta-button-wrapper">
          <SuawButton class="suaw-how-section__button" type="primary" size="hero" button-text="Create A Free Account" @click="$emit('click')" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-how-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: linear-gradient(180deg, var(--sem-color-background-light) 0%, var(--sem-color-info-light) 100%);
  padding-top: 88px;
  @media screen and (max-width: 1024px) {
    padding-top: var(--sem-space-octuple);
  }
  @media screen and (max-width: 600px) {
    padding-top: var(--sem-space-sextuple);
  }
  &__main-container {
    max-width: 1264px;
    margin: 0 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 56px;
    row-gap: 56px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      row-gap: 48px;
    }
    @media (max-width: 767px) {
      margin: 0 16px;
    }
    @media (max-width: 650px) {
      row-gap: 32px;
    }
  }
  //header styles
  &__grid-header {
    grid-column: 1/4;
    grid-row: 1/2;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
    @media (max-width: 768px) {
      row-gap: 16px;
    }
    @media (max-width: 650px) {
      row-gap: 24px;
    }
  }
  &__heading-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @media (max-width: 650px) {
      width: 90%;
      justify-self: center;
    }
  }
  &__heading-how-wrapper {
    position: relative;
  }
  &__top-arrow {
    position: absolute;
    top: 50px;
    // top: 50%;
    right: 100%;
    height: auto;
    @media (max-width: 900px) {
      display: none;
    }
  }
  &__heading-write-wrapper {
    position: relative;
  }
  &__heading-write {
    margin: 0 6px;
    color: var(--sem-color-text-dark);
    font-size: 48px;
    line-height: 125%;
    font-weight: 700;
    font-family: var(--secondary-font-family);
    z-index: 1;
    @media (max-width: 400px) {
      font-size: 40px;
    }
  }
  &__logo-background {
    position: absolute;
    width: 170px;
    height: auto;
    z-index: 0;
    left: -8%;
    top: 25%;
    @media (max-width: 400px) {
      font-size: 40px;
      top: 25%;
      left: -5%;
      width: 140px;
    }
  }
  &__heading {
    color: var(--sem-color-text-dark);
    font-size: 48px;
    line-height: 125%;
    font-weight: 600;
    font-family: var(--secondary-font-family);
    z-index: 1;
    margin: 0 5px;
    @media (max-width: 400px) {
      font-size: 40px;
    }
  }
  &__subheading {
    color: var(--sem-color-text-medium);
    font-size: 24px;
    @media (max-width: 400px) {
      font-size: 24px;
    }
  }
  //steps styles
  &__grid-steps {
    grid-column: 1/4;
    grid-row: 2/3;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      row-gap: 72px;
      width: 90%;
      justify-self: center;
    }
    @media (max-width: 650px) {
      width: auto;
    }
  }
  &__grid-step {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 24px;
    @media (max-width: 768px) {
      row-gap: 16px;
    }
  }
  &__step-caption {
    grid-row: 2/3;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 12px;
    @media (max-width: 768px) {
      grid-row: 1/2;
    }
  }
  .gather-caption {
    grid-column: 1/2;
  }
  .write-caption {
    grid-column: 2/3;
    @media (max-width: 768px) {
      grid-column: 1/2;
    }
  }
  &__step-heading {
    font-family: var(--secondary-font-family);
    font-weight: bold;
    font-size: 36px;
    @media (max-width: 768px) {
      font-size: 42px;
    }
    @media (max-width: 650px) {
      font-size: 36px;
    }
  }
  &__step-text {
    height: 70px;
    @media (max-width: 768px) {
      height: auto;
      font-size: 24px;
      justify-self: center;
    }
    @media (max-width: 650px) {
      font-size: 20px;
    }
  }
  &__step-image {
    grid-row: 1/2;
    width: 100%;
    height: 260px;
    justify-self: center;
    border-radius: 16px;
    box-shadow: var(--soft-shadow-draft);
    object-fit: cover;
    @media (max-width: 768px) {
      grid-row: 2/3;
      // height: auto;
    }
  }
  .gather-image {
    grid-column: 1/2;
  }
  .write-image {
    grid-column: 2/3;
    @media (max-width: 768px) {
      grid-column: 1/2;
    }
  }
  &__step-arrow {
    grid-row: 2/3;
    justify-self: end;
    @media (max-width: 768px) {
      display: none;
    }
  }
  &__step-arrow-mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
      grid-column: 1/2;
      grid-row: 2/3;
      width: 100px;
      align-self: end;
    }
    @media (max-width: 500px) {
      width: 85px;
    }
    @media (max-width: 350px) {
      display: none;
    }
  }
  .gather-arrow {
    grid-column: 1/2;
    transform: translate(80px, 5px);
  }
  .gather-arrow-mobile {
    transform: translate(20%, 90px);
    @media (max-width: 500px) {
      transform: translate(15%, 80px);
    }
  }
  .write-arrow {
    grid-column: 2/3;
    transform: translate(75px, 7px);
  }
  .write-arrow-mobile {
    justify-self: end;
    transform: translate(-20%, 110px);
    @media (max-width: 500px) {
      transform: translate(-15%, 90px);
    }
  }
  //cta-styles
  &__grid-cta {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;
    justify-self: center;
    grid-column: 1/4;
    grid-row: 3/4;
    max-width: 800px;
  }
  &__cta-divider {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  &__cta-heading {
    grid-column: 1/2;
    grid-row: 2/3;
    color: var(--sem-color-text-medium);
    font-family: var(--secondary-font-family);
    text-align: center;
    font-weight: bold;
  }
  &__cta-arrow {
    position: absolute;
    top: 105px;
    right: -28px;
    width: 200px;
    height: auto;
    @media (max-width: 650px) {
      display: none;
    }
  }
  &__cta-arrow-mobile {
    display: none;
    @media (max-width: 590px) {
      transform: translate(20%, 70px);
    }
    @media (max-width: 374px) {
      display: none;
    }
  }
  &__cta-button-wrapper {
    grid-column: 1/2;
    grid-row: 3/4;
    display: flex;
    justify-content: center;
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}
</style>
