<script>
import { SuawAvatarTwo, SuawHeading, SuawParagraph } from "@suaw/suaw-component-library";
export default {
  name: "TestimonialSection",
  components: { SuawHeading, SuawAvatarTwo, SuawParagraph }
};
</script>

<template>
  <div class="suaw-testimonial-section">
    <div class="suaw-testimonial-section__main-container">
      <div class="suaw-testimonial-section__heading-container">
        <SuawHeading class="suaw-testimonial-section__heading" content="Testimonials" level="1" />
      </div>
      <div class="suaw-testimonial-section__cards-container">
        <div class="suaw-testimonial-section__card-container">
          <div class="suaw-testimonial-section__card-avatar-container">
            <SuawAvatarTwo class="suaw-testimonial-section__card-avatar" size="large" display-name="Sarah T" initials="ST" color="light-purple" />
          </div>
          <div class="suaw-testimonial-section__card-heading-container">
            <SuawHeading
              class="suaw-testimonial-section__card-heading"
              level="4"
              alignment="center"
              content='"Shut Up & Write! transformed my writing habits. The support and accountability from my group were invaluable."'
            />
          </div>
          <div class="suaw-testimonial-section__card-name-container">
            <SuawParagraph class="suaw-testimonial-section__card-name" text="Sarah, San Fransisco" />
          </div>
        </div>
        <div class="suaw-testimonial-section__card-container">
          <div class="suaw-testimonial-section__card-avatar-container">
            <SuawAvatarTwo class="suaw-testimonial-section__card-avatar" size="large" display-name="John S" initials="JS" color="light-red" />
          </div>
          <div class="suaw-testimonial-section__card-heading-container">
            <SuawHeading
              class="suaw-testimonial-section__card-heading"
              level="4"
              alignment="center"
              content='"Hosting my own Shut Up & Write! sessions has been incredibly rewarding. The resources made it so easy to get started."'
            />
          </div>
          <div class="suaw-testimonial-section__card-name-container">
            <SuawParagraph class="suaw-testimonial-section__card-name" text="John, London" />
          </div>
        </div>
        <div class="suaw-testimonial-section__card-container">
          <div class="suaw-testimonial-section__card-avatar-container">
            <SuawAvatarTwo class="suaw-testimonial-section__card-avatar" size="large" display-name="Aaron B" initials="AB" color="light-green" />
          </div>
          <div class="suaw-testimonial-section__card-heading-container">
            <SuawHeading
              class="suaw-testimonial-section__card-heading"
              level="4"
              alignment="center"
              content='"It was so easy to get started. The team gave me everything I needed and the group of people who came are really amazing!"'
            />
          </div>
          <div class="suaw-testimonial-section__card-name-container">
            <SuawParagraph class="suaw-testimonial-section__card-name" text="Aaron, Tulsa" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-testimonial-section {
  background: var(--sem-color-info-light);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 88px 0px;
  @media screen and (max-width: 1024px) {
    padding: var(--sem-space-octuple) 0px;
  }
  @media screen and (max-width: 600px) {
    padding: var(--sem-space-sextuple) 0px;
  }
  &__main-container {
    max-width: 1264px;
    margin: 0 24px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 48px;
    @media (max-width: 767px) {
      margin: 0 16px;
    }
  }
  &__heading-container {
    justify-self: center;
  }
  &__heading {
    line-height: 125%;
    font-family: var(--secondary-font-family);
    font-weight: 600;
  }
  &__cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    @media (max-width: 650px) {
      grid-template-columns: 1fr;
    }
  }

  &__card-container {
    grid-row: 2/3;
    background-color: var(--sem-color-background-lightest);
    box-shadow: var(--soft-shadow-draft);
    border-radius: var(--sem-radius-lg);
    padding: var(--sem-space-triple);
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    row-gap: 14px;
    @media (max-width: 650px) {
      grid-row: auto;
    }
  }

  &__card-avatar-container {
    justify-self: center;
    margin-bottom: 10px;
  }

  &__card-heading {
    min-height: 100px;
    @media (max-width: 1024px) {
      min-height: 125px;
    }
    @media (max-width: 820px) {
      min-height: 200px;
    }
    @media (max-width: 650px) {
      min-height: auto;
    }
  }

  &__card-name {
    font-size: 20px;
    color: var(--sem-color-text-regular);
    font-weight: 500;
    text-align: center;
  }
}
</style>
