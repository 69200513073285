<script>
import { SuawHeading, SuawButton, SuawDivider } from "@suaw/suaw-component-library";
import logoWriteBackground from "../../../../public/img/home/logo-write-background.svg";
import whyImage from "../../../../public/img/home/why-image.png";
import whyHighlight from "../../../../public/img/home/why-highlight.svg";
export default {
  name: "WhySection",
  components: { SuawHeading, SuawButton, SuawDivider },
  data() {
    return {
      logoWriteBackground,
      whyImage,
      whyHighlight
    };
  }
};
</script>

<template>
  <div class="suaw-why-section">
    <div class="suaw-why-section__main-container">
      <div class="suaw-why-section__heading-container">
        <div class="suaw-why-section__heading-wrapper">
          <SuawHeading class="suaw-why-section__heading" level="1" alignment="center" content="Why" />
          <SuawHeading class="suaw-why-section__heading" level="1" alignment="center" content="You" />
          <SuawHeading class="suaw-why-section__heading" level="1" alignment="center" content="Should" />
          <SuawHeading class="suaw-why-section__heading" level="1" alignment="center" content="Shut" />
          <SuawHeading class="suaw-why-section__heading" level="1" alignment="center" content="Up" />
          <SuawHeading class="suaw-why-section__heading" level="1" alignment="center" content="&amp;" />
          <div class="suaw-why-section__heading-write-wrapper">
            <b class="suaw-why-section__heading-write">Write!</b>
            <img class="suaw-why-section__logo-background" :src="logoWriteBackground" alt="Logo Write Background" />
          </div>
        </div>
      </div>
      <div class="suaw-why-section__reasons-container">
        <div class="suaw-why-section__reason-image-container">
          <img class="suaw-why-section__image" :src="whyImage" />
        </div>
        <!-- <img class="suaw-why-section__highlight" :src="whyHighlight" alt="Why Highlight" /> -->
        <div class="suaw-why-section__reason-container">
          <div class="suaw-why-section__reason-heading-container">
            <SuawHeading class="suaw-why-section__subheading" level="3" weight="bold" content="Proven Methodology" />
          </div>
          <div class="suaw-why-section__reason-text-container">
            <SuawHeading
              class="suaw-why-section__text"
              level="5"
              content="Our approach has helped thousands of writers over the last 20 years overcome procrastination, boost productivity, and achieve their goals."
            />
          </div>
        </div>
        <div class="suaw-why-section__reason-container">
          <div class="suaw-why-section__reason-heading-container">
            <SuawHeading class="suaw-why-section__subheading" level="3" content="Global Community" />
          </div>
          <div class="suaw-why-section__reason-text-container">
            <SuawHeading
              class="suaw-why-section__text"
              level="5"
              content="Our diverse network of writers is committed to supporting each other throughout the writing process. Friendships and connections form when our writers gather together regularly."
            />
          </div>
        </div>
        <div class="suaw-why-section__reason-container">
          <div class="suaw-why-section__reason-heading-container">
            <SuawHeading class="suaw-why-section__subheading" level="3" content="Flexible and Inclusive" />
          </div>
          <div class="suaw-why-section__reason-text-container">
            <SuawHeading
              class="suaw-why-section__text"
              level="5"
              content="Whether you're urban or rural, a novice writer or published author, our events are designed to work for any setting and any group. And with over 150 online events every week, you can write with us anytime, anywhere."
            />
          </div>
        </div>
      </div>
      <div class="suaw-why-section__cta-container">
        <SuawDivider class="suaw-why-section__cta-divider" type="inner" />
        <div class="suaw-why-section__cta-heading-container">
          <SuawHeading class="suaw-why-section__cta-heading" level="2" content="Join and find out how thousands of writers each week get their writing done together!" />
        </div>
        <div class="suaw-why-section__cta-buttons-container">
          <SuawButton class="suaw-why-section__cta-button" type="primary" size="hero" button-text="Find a Community Near You" @click="$emit('click-find')" />
          <SuawButton class="suaw-why-section__cta-button" type="ghost-outline" size="hero" button-text="Become an Organizer" @click="$emit('click-become')" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-why-section {
  background: var(--sem-color-info-light);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 88px;
  @media screen and (max-width: 1024px) {
    padding-top: var(--sem-space-octuple);
  }
  @media screen and (max-width: 600px) {
    padding-top: var(--sem-space-sextuple);
  }
  &__main-container {
    max-width: 1264px;
    margin: 0 24px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 48px;
    @media (max-width: 767px) {
      margin: 0 16px;
    }
  }
  &__heading-container {
    grid-column: 1/3;
    display: flex;
    justify-content: center;
  }
  &__reasons-container {
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 56px;
    row-gap: 48px;
    @media (max-width: 650px) {
      grid-template-columns: 1fr;
    }
  }
  &__reason-image-container {
    max-height: 394px;
    grid-column: 1/2;
    grid-row: 1/4;
    justify-items: center;
    align-items: center;
    @media (max-width: 1219px) {
      max-height: 420px;
    }
    @media (max-width: 1078px) {
      max-height: 600px;
    }
    @media (max-width: 650px) {
      grid-row: auto;
    }
  }

  &__highlight {
    grid-column: 1/2;
    grid-row: 3/4;
    align-self: end;
    transform: translate(-55px, 45px);
    @media (max-width: 1220px) {
      display: none;
    }
  }

  &__reason-container {
    grid-column: 2/3;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    @media (max-width: 650px) {
      grid-column: auto;
    }
  }
  &__cta-container {
    grid-column: 1/3;
  }
  &__heading-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    @media (max-width: 650px) {
      width: 90%;
      justify-self: center;
    }
  }

  &__heading {
    z-index: 1;
    color: var(--sem-color-text-dark);
    font-size: 48px;
    line-height: 125%;
    font-weight: 600;
    margin: 0 5px;
    font-family: var(--secondary-font-family);
  }

  &__heading-write-wrapper {
    position: relative;
  }
  &__heading-write {
    margin: 0 6px;
    color: var(--sem-color-text-dark);
    font-size: 48px;
    line-height: 125%;
    font-weight: 700;
    font-family: var(--secondary-font-family);
    z-index: 1;
    @media (max-width: 400px) {
      font-size: 40px;
    }
  }

  &__subheading {
    font-weight: 700;
  }

  &__logo-background {
    position: absolute;
    width: 170px;
    height: auto;
    z-index: 0;
    left: -8%;
    top: 25%;
    @media (max-width: 400px) {
      font-size: 40px;
      top: 25%;
      left: -5%;
      width: 140px;
    }
  }

  &__cta-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;
    justify-self: center;
    max-width: 800px;
    row-gap: 32px;
  }

  &__cta-divider {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  &__cta-heading {
    grid-column: 1/2;
    grid-row: 2/3;
    color: var(--sem-color-text-medium);
    font-family: var(--secondary-font-family);
    text-align: center;
    font-weight: bold;
  }
  &__cta-buttons-container {
    justify-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 32px;
    @media (max-width: 650px) {
      width: 100%;
      grid-template-columns: 1fr;
    }
  }

  &__cta-button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }

  &__text {
    color: var(--sem-color-neutral-medium);
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    box-shadow: var(--soft-shadow-draft);
    object-fit: cover;
  }
}
</style>
