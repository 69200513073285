import {
  SubscribeNewsletter
} from "./operations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
// import { Logger, DEBUG } from "@/utils/plugins/logging";
import { call } from "@/utils/api/base.js";

// const $log = new Logger("CommonApi", { level: DEBUG });

export async function subscribeNewsletter(email) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.INVALID_FORMAT, "This email format is invalid."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Unable to subscribe. Please try again or contact support if the issue persists."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: SubscribeNewsletter,
        variables: {
          email
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
