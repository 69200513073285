<script>
import { SuawDivider } from "@suaw/suaw-component-library";
import NewsletterSubscribe from "../../common/components/NewsletterSubscribe.vue";
export default {
  name: "NewsletterSection",
  components: { NewsletterSubscribe, SuawDivider }
};
</script>

<template>
  <div class="suaw-newsletter-section">
    <SuawDivider type="outer" />
    <div class="suaw-newsletter-section__main-container">
      <NewsletterSubscribe />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-newsletter-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 44px 0px;
  &__main-container {
    margin-top: 44px;
    max-width: 1264px;
    width: calc(100% - 48px);
    @media (max-width: 767px) {
      width: calc(100% - 32px);
    }
  }
}
</style>
